<template>
  <RoomSocket ref="backend" :room-id="roomId" @context-update="updateContext" @connected="onConnect"
    @disconnected="onDisconnect" />
  <Game v-if="connected" :player-context="playerContext" :room-code="roomCode" @submit="submitToSocket"
    @new-game="newGame" @main-page="mainPage" :room-id="roomId" />
  <SplashScreen v-else />
</template>

<script>
import Game from "./Game";
import RoomSocket from "./RoomSocket";
import SplashScreen from "./SplashScreen";

export default {
  name: "Room",
  components: { Game, RoomSocket, SplashScreen },
  emits: ["newGame", "mainPage"],
  props: {
    roomId: String,
    roomCode: String,
  },
  data: () => ({
    connected: false,
    playerContext: null,
  }),
  methods: {
    submitToSocket(submission) {
      this.$refs.backend.submit(submission);
    },
    updateContext(newContext) {
      this.playerContext = newContext;
    },
    newGame() {
      this.$emit("newGame");
    },
    newSinglePlayerGame() {
      this.$emit("newGame");
    },
    mainPage() {
      this.$emit("mainPage");
    },
    onDisconnect() {
      this.connected = false;
    },
    onConnect() {
      this.connected = true;
    }
  },
};
</script>

<style scoped></style>
