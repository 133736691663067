<template>
  <div style="display: none">{{ connected }}</div>
</template>

<script>
import { PlayerContext } from "./proto/game_pb";

export default {
  name: "RoomSocket",
  props: {
    roomId: String,
  },
  emits: ["contextUpdate", "connected", "disconnected"],
  data: () => ({
    connected: false,
    socket: null,
    reconnect: true,
  }),
  methods: {
    submit: function (submission) {
      if (this.connected) {
        try {
          this.socket.send(submission.serializeBinary());
        } catch (err) {
          console.log("Failed to submit", submission.toObject(), err);
        }
      }
    },
    InitSocket(roomId) {
      const RECONNECT_TIMEOUT = 2000; // 2 seconds
      this.CleanupSocket();
      this.reconnect = true;
      let connectSocket;
      const wsURL = `${window.location.protocol === "http:" ? "ws" : "wss"}:${window.location.host
        }/ws/${roomId}`;
      connectSocket = () => {
        if (this.roomId != roomId) {
          return;
        }
        this.socket = new WebSocket(wsURL);
        this.socket.binaryType = "arraybuffer";
        this.socket.addEventListener("open", () => {
          this.connected = true;
          this.$emit("connected");
        });
        this.socket.addEventListener("close", (err) => {
          console.log("Lost socket connection!");
          this.connected = false;
          this.$emit("disconnected");
          if (this.roomId == roomId) {
            if (err.code == 1006) {
              console.log("Abnormal Closure 1006...");
              fetch(`/ws/${roomId}`).then(rez => {
                if (rez.status == 404) {
                  this.$router.push("/");
                }
              }).catch(err => {
                console.log("Network error fetching. Assume retry", err)
              });
            }
            setTimeout(() => {
              if (this.reconnect) {
                console.log("Attempting to reconnect...");
                connectSocket();
              }
            }, RECONNECT_TIMEOUT);
          }
        });
        this.socket.addEventListener("message", (event) => {
          console.log(`Got a socket message: ${event.data}`);
          var status = PlayerContext.deserializeBinary(event.data);
          console.log(`I'm in slot ${status.getSlot()}`);
          this.$emit("contextUpdate", status);
        });
      };
      connectSocket();
    },
    CleanupSocket() {
      this.reconnect = false;
      if (this.socket) {
        console.log("Cleaning up old socket");
        try {
          this.socket.close();
        } catch (err) {
          console.log("Failed to close socket", err);
        }
      }
    },
  },
  watch: {
    roomId(val) {
      if (val) {
        this.InitSocket(val);
      } else {
        console.log("roomId is lost!")
        this.CleanupSocket();
      }
    },
  },
  mounted() {
    this.InitSocket(this.roomId);
  },
  beforeUnmount() {
    this.CleanupSocket();
    localStorage.removeItem(`${this.roomId}-hottips`)
  },
};
</script>

<style scoped>

</style>
